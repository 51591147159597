<template>
  <div class="body">
    <el-tabs v-model="activeTab" type="card" @tab-click="changeTabs">
      <el-tab-pane label="卡信息" name="first">
        <div style="margin: 20px;">
          <div class="vm-separate">
            <span>imsi：{{cardInfo.imsi}}</span>
            <span>卡状态：{{cardInfo.account_status === "00" ? "正在使用" : "未使用"}}</span>
            <span></span>
          </div>
        </div>
        <div style="margin: 20px;">
          <div class="vm-separate">
            <span>是否激活：{{cardInfo.active ? "是" : "否"}}</span>
            <span>激活日期：{{cardInfo.active_date}}</span>
            <span></span>
          </div>
        </div>
        <div style="margin: 20px;">
          <div class="vm-separate">
            <span>当月已使用流量：{{cardInfo.data_usage}}<b> MB</b></span>
            <span>剩余流量：{{cardInfo.data_balance}}<b> MB</b></span>
            <span></span>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="使用流量日志" name="third">
        <div style="margin: 20px;">
          <div>
            <span>查询月份</span>
            <el-date-picker
              v-model="month"
              type="month"
              value-format="yyyyMM"
              placeholder="选择月"
              @change="getMonthyDosage">
            </el-date-picker>
          </div>
          <el-table
            :data="monthyDosageLog"
            style="width: 50%">
            <el-table-column
              prop="date"
              label="日期"
              width="180">
            </el-table-column>
            <el-table-column
              prop="data_usage"
              label="每日使用流量（MB）"
              width="180">
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeTab: "first",
        iccid: "",
        month: this.dateFormat("YYYYmm", new Date()),
        cardInfo: {
          imsi: "",
        },
        monthyDosageLog: [],
      };
    },
    mounted() {
      this.iccid = this.$route.params.iccid;
      this.getCardInfo();
      this.getMonthyDosage();
    },
    methods: {
      getCardInfo() {
        let params;
        params = {
          iccid: this.iccid,
        };
        this.$api.getData("simCard/getCardInfo", params).then(res => {
          console.log(res.data);
          this.cardInfo = res.data;
        }).catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          }
        });
      },
      getMonthyDosage() {
        let params;
        params = {
          iccid: this.iccid,
          month: this.month,
        };
        this.$api.getData("simCard/getMonthyDosage", params).then(res => {
          console.log(res.data);
          this.monthyDosageLog = res.data.log;
        }).catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          }
        });
      },
      dateFormat(fmt, date) {
        let ret;
        const opt = {
          "Y+": date.getFullYear().toString(),        // 年
          "m+": (date.getMonth() + 1).toString(),     // 月
          "d+": date.getDate().toString(),            // 日
          "H+": date.getHours().toString(),           // 时
          "M+": date.getMinutes().toString(),         // 分
          "S+": date.getSeconds().toString(),          // 秒
          // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
          ret = new RegExp("(" + k + ")").exec(fmt);
          if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")));
          }
        }
        return fmt;
      },
      changeTabs(tab, event) {
      },
    },
  };
</script>
<style lang="scss" scoped>
.body {
  margin: 10px 40px 10px 40px;
  padding: 20px 60px 20px 60px;
  border: solid 1px #DCDFE6;
}

span {
  color: #606266;
  font-size: 16px;
}

.vm-separate {
  margin-bottom: 18px;
}
</style>
